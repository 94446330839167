import React from 'react';
import DataWrapChart from '../datawrap';





function DemTrends() {



    
    return (  
        
          <section className="usa-graphic-list usa-section">

              
                  <div className='grid-container'>
                  <a name="#dem_break"></a>  <h1>Demographic Breakdown</h1>
                
                  <div><hr></hr></div>
                  
                  </div>

                  <div className='grid-container'>

                  </div>
                  <div className='grid-container'>
                  <DataWrapChart frameborder="0" aria-label="Table" id="datawrapper-chart-Fucs6"  height="500"  allowfullscreen="true" src='https://datawrapper.dwcdn.net/8RZnI/8/'/>
                  </div>

                  <div className='grid-container'>
                  <DataWrapChart frameborder="0" aria-label="Table" id="datawrapper-chart-Fucs6"  height="500"  allowfullscreen="true" src='https://datawrapper.dwcdn.net/Fq0vW/4/'/>
                  </div>

                  <div className='grid-container'>
                  <DataWrapChart frameborder="0" aria-label="Table" id="datawrapper-chart-Fucs6"  height="500"  allowfullscreen="true" src='https://datawrapper.dwcdn.net/6CScW/6/'/>
                  </div>
              
                  </section>
          



    );
  };



export default DemTrends;
